import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`This week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 3 of the numbered questions posed in the threads below `}<strong parentName="li">{`by 5pm Tuesday.`}</strong></li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 2 more replies `}<strong parentName="li">{`by 5pm Friday.`}</strong></li>
    </ul>
    <h2>{`11.1 Going with your gut`}</h2>
    <p>{`Recall and react to one of the points Walter made about intuition and “going with your gut”?`}</p>
    <h2>{`11.2 Skepticism and Laziness`}</h2>
    <p>{`How are skepticism and laziness factors in designing systems? Recall and react to one of the tips that Walter gave for overcoming them?`}</p>
    <h2>{`11.3 Apathy`}</h2>
    <p>{`What role does apathy play according to Walter? How can we overcome it?`}</p>
    <h2>{`11.4 Forgiveness`}</h2>
    <p>{`React to one of the examples Walter provided for the idea of forgiveness.`}</p>
    <h2>{`11.5 Other Forgiveness Examples`}</h2>
    <p>{`Share an example you've encountered in which the way an offending party responded to their mistake that impacted others' willingness to forgive. Feel free to venture outside of interactive design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      